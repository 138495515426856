import * as React from 'react'
import PropTypes from 'prop-types'
import joinClasses from '/src/functions/joinClasses'
import './styles.scss'

const OrderedList = ({ children, className }) => {
    const classes = ['c-ordered-list', ...(className ? [className] : [])]

    const concatenatedClasses = joinClasses(classes)

    return <ol className={concatenatedClasses}>{children}</ol>
}

OrderedList.propTypes = {
    /**
     * Inner child elements
     */
    children: PropTypes.node.isRequired,
    /**
     * Optional CSS classes
     */
    className: PropTypes.string
}

OrderedList.defaultProps = {
    children: '<li>I am a list item</li>'
}

export default OrderedList
